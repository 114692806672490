<template>
  <div class="subscription-view">
    <div class="logo-section">
      <img
        src="@/assets/spartan_edge_logo.svg"
        alt="spartan edge"
        @click="$router.push({ name: 'home' })"
      />
    </div>
    <div class="view-elements" v-if="showSuccess">
      <div class="success-subscription">
        <div class="success-notification">
          <i class="material-icons">check_circle_outline</i>
          <div class="section-title success-text">
            {{ $t("payment_successful") }}
          </div>
          <div class="section-title success-text">
            {{ $t("you_plus_member") }}
          </div>
          <div class="section-title success-text-mobile">
            {{ $t("payment_successful") + " " + $t("you_plus_member") }}
          </div>
          <button class="mem-btn btn-primary" @click="finishSubscription()">
            {{ $t("AROO!") }}
          </button>
        </div>
      </div>
    </div>
    <div class="view-elements" v-if="!showSuccess">
      <div class="price-section">
        <div class="section-title">
          <span v-if="showTrialState">{{
            $t("trial_subscription_title")
          }}</span>
          <span v-else>{{ $t("subscription_title") }}</span>
        </div>
        <div class="subscriptions-list">
          <template v-for="(product, index) in stripeProducts">
            <subscription-card
              :key="index"
              :product="product"
              :activeProduct="activeStripeProduct"
              :isLoading="productsLoading"
              :isCodeValid="isCodeValid"
              @change-product="changeStripeProduct"
            />
          </template>
        </div>

        <!-- promo code -->
        <div class="subscription-code">
          <div class="section-subtitle">{{ $t("redemption_code") }}</div>
          <div class="code-input">
            <input
              type="text"
              :placeholder="$t('code')"
              v-model="code"
              v-on:input="onChange"
              :style="{
                color: !showApplyBtn && !isCodeValid ? '#D1595F' : '#FFFFFF',
              }"
            />
            <div
              v-if="showApplyBtn"
              class="input-state apply-btn cursor-pointer"
              @click="applyCode"
            >
              <span>{{ $t("apply_code") }}</span>
            </div>
            <div
              v-if="!showApplyBtn && isCodeValid"
              class="input-state succes-state"
            >
              <i class="material-icons">check_circle</i>
              <span>{{ $t("applied") }}</span>
            </div>
            <div
              v-if="!showApplyBtn && !isCodeValid"
              class="input-state error-state"
            >
              <i class="material-icons">error</i>
              <span>{{ $t("invalid_code") }}</span>
            </div>
          </div>
          <div class="code-input__errors">
            <span v-if="!showApplyBtn && !isCodeValid">{{
              $t(errorText)
            }}</span>
          </div>
        </div>
      </div>

      <div class="form-section">
        <div class="section-title">{{ $t("sub_title_1") }}</div>
        <div class="section-title">
          <span v-if="showTrialState">{{
            $t("trial_subscription_title_2")
          }}</span>
          <span v-else>{{ $t("sub_title_2") }}</span>
        </div>
        <form id="subscription-form">
          <div class="section-subtitle">{{ $t("full_name") }}</div>
          <div class="input-border">
            <input
              id="name"
              type="text"
              :placeholder="$t('first_and_last_name')"
            />
          </div>
          <div class="section-subtitle">{{ $t("card") }}</div>
          <div id="card-element" ref="card" class="card-input">
            <!-- Elements will create input elements here -->
          </div>

          <!-- We'll put the error messages in this element -->
          <div id="card-errors" role="alert"></div>
          <div class="form-buttons">
            <button
              class="mem-btn btn-primary"
              type="button"
              v-if="!loading"
              @click="subscribeUser"
            >
              <span v-if="showTrialState">{{
                $t("subscribe_with_trial")
              }}</span>
              <span v-else>{{ $t("subscribe") }}</span>
            </button>
            <div v-if="loading" class="sub-loader">
              <v-progress-circular
                indeterminate
                color="#cf1019"
                :size="52"
              ></v-progress-circular>
            </div>
          </div>
        </form>
      </div>
      <div class="terms-section">
        <div class="terms-text">
          <span>{{ $t("subscription_terms_text") }}</span>
          <a href="http://spartan.com/pages/terms-conditions">{{
            $t("subscription_terms_link")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { loadStripe } from "@stripe/stripe-js";
import { head } from "lodash";

import config from "@/config";
import { pushDataLayerEvent } from "@/common/helpers";

function showCardError(event) {
  let displayError = document.getElementById("card-errors");
  if (event.error) {
    displayError.textContent = event.error.message;
  } else {
    displayError.textContent = "";
  }
}
function displayError(err) {
  console.log(err, "elementss error");
}

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    "subscription-card": () => import("./components/SubscriptionCard.vue"),
  },
  data: () => ({
    productsLoading: true,

    showSuccess: false,
    loading: false,
    redirectUrl: "",
    code: null,
    showApplyBtn: true,
    isCodeValid: false,
    errorText: "",
    // canChangePlan: true,
    // stripe elements
    stripe: null,
    cardElement: null,
  }),
  computed: {
    ...mapGetters([
      "userData",
      "subscriptionStatus",
      "isAuthorized",
      "stripeProducts",
      "activeStripeProduct",
    ]),
    dataLayerPayload() {
      let planPrice = this.activeStripeProduct.price;

      return {
        currency: "USD",
        value: planPrice,
        transaction_country: this.userData?.spartan_country_code,
        item_name: this.activeStripeProduct.name,
        item_price: planPrice,
        item_id: this.activeStripeProduct.price_id,
        userData: this.userData,
      };
    },
    showTrialState() {
      return this.userData.subscription_trial_allowed;
    },
  },
  methods: {
    ...mapActions([
      "stripeAddUser",
      "stripeGetProducts",
      "createSubscription",
      "getUserData",
      "checkCode",
    ]),
    ...mapMutations(["setActiveStripeProduct"]),
    async checkUser() {
      if (!this.isAuthorized) return;

      if (this.subscriptionStatus && !this.showSuccess)
        this.$router.push("/home");

      // Check users region
      if (
        this.userData.spartan_country_code === "US" ||
        !this.userData.spartan_country_code
      ) {
        // Check if user added to Stripe
        if (!this.userData.stripe_customer_identifier)
          await this.stripeAddUser();
      } else {
        this.$router.push("/home");
      }
    },
    subscribeUser() {
      // If a previous payment was attempted, get the latest invoice
      const latestInvoicePaymentIntentStatus = localStorage.getItem(
        "latestInvoicePaymentIntentStatus"
      );
      this.loading = true;
      if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
        const invoiceId = localStorage.getItem("latestInvoiceId");
        const isPaymentRetry = true;
        // create new payment method & retry payment on invoice with new payment method
        this.createPaymentMethod({
          cardElement: this.cardElement,
          isPaymentRetry,
          invoiceId,
        });
      } else {
        // create new payment method & create subscription
        this.createPaymentMethod({ cardElement: this.cardElement });
      }
    },
    showFormError(text) {
      let errorLine = document.getElementById("card-errors");
      if (text) errorLine.textContent = text;
      else errorLine.textContent = "";
    },
    createPaymentMethod({ cardElement, isPaymentRetry, invoiceId }) {
      // Set up payment method for recurring usage
      let billingName = document.querySelector("#name").value;
      console.log(invoiceId, billingName);
      if (!billingName) {
        this.showFormError(this.$t("error_cardholder_name"));
      } else {
        this.showFormError();
      }

      this.stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            name: billingName,
          },
        })
        .then((result) => {
          if (result.error) {
            displayError(result);
            this.loading = false;
          } else {
            if (isPaymentRetry) {
              console.log("Payment Retry ?");
              // Update the payment method and retry invoice payment
              // retryInvoiceWithNewPaymentMethod({
              //   customerId: customerId,
              //   paymentMethodId: result.paymentMethod.id,
              //   invoiceId: invoiceId,
              //   priceId: priceId,
              // });
            } else {
              // Create the subscription
              let promoCode;
              if (this.code && this.isCodeValid) promoCode = this.code;

              // console.log(promoCode, "code");
              this.createSubscription({
                // customerId: this.userData.stripe_customer_identifier,
                payment_method_id: result.paymentMethod.id,
                price_id: this.activeStripeProduct.price_id, /// !!!!
                product_id: this.activeStripeProduct.id,
                promotion_code: promoCode,
              })
                .then((result) => {
                  if (result.error) {
                    this.showFormError(this.$t("server_error"));
                    this.loading = false;
                  } else {
                    this.showSuccess = true;

                    pushDataLayerEvent(
                      "spartanPlusPurchase",
                      this.dataLayerPayload
                    );
                  }
                })
                .catch((err) => {
                  console.log(err, "sub error");
                  this.showFormError(this.$t("server_error"));
                  this.loading = false;
                });
            }
          }
        });
    },
    async finishSubscription() {
      if (this.redirectUrl) window.location = this.redirectUrl;
      else this.$router.push({ name: "home" });
      await this.getUserData();
    },
    async applyCode() {
      try {
        let result = await this.checkCode({
          code: this.code,
          product_id: this.activeStripeProduct.id,
        });
        this.showApplyBtn = false;
        if (result.active) this.isCodeValid = true;
      } catch (error) {
        this.showApplyBtn = false;
        this.isCodeValid = false;

        let { message } = error.error;
        switch (message) {
          case "expired":
            this.errorText = "code_expired";
            break;
          case "already_used":
            this.errorText = "code_already_used";
            break;
          case "not_found":
            this.errorText = "code_not_found";
            break;
          case "not_applicable":
            this.errorText = "code_wrong_plan";
            break;
          default:
            this.errorText = "code_not_found";
            break;
        }
      }
    },
    async onChange() {
      if (this.isCodeValid) {
        await this.stripeGetProducts();
        this.isCodeValid = false;
      }
      this.showApplyBtn = true;
    },
    async changeStripeProduct(product) {
      if (this.activeStripeProduct.id === product.id) return;
      this.setActiveStripeProduct(product);

      await this.onChange();
      this.code = null;
    },

    getParameterByName(name, url) {
      if (!url) url = window.location.href;
      // name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },
  },
  async mounted() {
    try {
      this.productsLoading = true;
      await this.stripeGetProducts();
      this.setActiveStripeProduct(head(this.stripeProducts));
      this.productsLoading = false;
    } catch (error) {
      console.log("Stripe products error", error);
    }
    // init stripe elements
    this.stripe = await loadStripe(config.stripe.key);
    let montserrat = {
      cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:600",
    };
    let elements = this.stripe.elements({ fonts: [montserrat] });
    const style = {
      base: {
        color: "#757474",
        fontFamily: '"Montserrat", sans-serif',
        fontSmoothing: "antialiased",
        fontWeight: "600",
        fontSize: "14px",
        // lineHeight: "21px",
        "::placeholder": {
          color: "#757474",
          textTransform: "uppercase",
        },
      },
      invalid: {
        color: "#CF1019",
        iconColor: "#CF1019",
      },
    };
    this.cardElement = elements.create("card", { style: style });

    this.cardElement.on("change", showCardError);

    this.cardElement.mount(this.$refs.card);
    await this.checkUser();
  },
  async created() {
    this.redirectUrl = this.getParameterByName("redirect");
    // let plan = this.getParameterByName("plan"),
    //   code = this.getParameterByName("promo");
    // if (plan === "month") {
    //   this.selectedPlanId = 1;
    //   this.changePlan(this.activeStripeProduct);
    // }
    // if (code) {
    //   this.code = code;
    //   this.canChangePlan = false;
    //   await this.applyCode();
    //   this.canChangePlan = true;
    // }
  },
  // destroyed() {},
  watch: {
    async isAuthorized() {
      await this.checkUser();
    },
  },
};
</script>
<style lang="scss" scoped>
.subscription-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  .logo-section {
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0 35px 0;
  }
  .view-elements {
    max-width: 722px;
    width: 100%;

    .price-section,
    .form-section,
    .success-subscription {
      background: #121212;
      border-radius: 5px;
      .section-title {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
      }
      .section-subtitle {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .price-section {
      margin-bottom: 4px;
      padding: 41px 86px;
      .subscriptions-list {
        margin-top: 41px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 14px;
        min-height: 130px;
        .subscriptions-item {
          border: 1px solid #717171;
          border-radius: 8px;
          padding: 11px 23px 18px 17px;
          cursor: pointer;
          outline: none;
          .sub-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            .section-subtitle {
              text-transform: capitalize;
            }
            .best-value {
              height: 15px;
              text-transform: uppercase;
              color: #cf1019;
              font-weight: bold;
              font-size: 12px;
              line-height: 15px;
            }
          }
          .sub-price {
            margin: 10px 0;
            font-weight: 800;
            font-size: 24px;
            line-height: 29px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
          }
          .sub-description {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #717171;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            .info-icon {
              position: relative;
              height: 14px;
              width: 14px;
              i {
                font-size: 14px;
                position: absolute;
                top: 0;
                left: 5px;
                z-index: 5;
              }
            }
            // tooltip on hover view
            .subscription-hint {
              margin-left: 5px;
              position: relative;
              height: 14px;
              width: 14px;
              z-index: 10;
            }
            .subscription-hint::before,
            .subscription-hint::after {
              --scale: 0;
              --arrow-size: 10px;
              --tooltip-color: #ffffff;

              position: absolute;
              // top: -0.25rem;
              left: 50%;
              transform: translateX(-50%) translateY(var(--translate-y, 0))
                scale(var(--scale));
              transition: 150ms transform;
              transform-origin: top center;
            }
            .subscription-hint::before {
              --translate-y: calc(1.1rem + var(--arrow-size));

              content: attr(data-tooltip);
              color: #000000;
              font-size: 12px;
              line-height: 18px;
              padding: 16px;
              border-radius: 0.3rem;
              text-align: start;
              width: max-content;
              max-width: 192px;
              background: var(--tooltip-color);
            }
            .subscription-hint:hover::before,
            .subscription-hint:hover::after {
              --scale: 1;
            }
            .subscription-hint::after {
              --translate-y: calc(1.15rem - var(--arrow-size));

              content: "";
              border: var(--arrow-size) solid transparent;
              border-bottom-color: var(--tooltip-color);
              transform-origin: bottom center;
            }
            // end tooltip on hover view

            // tooltip on click view
            .mobile-hint {
              height: 14px;
              width: 14px;
              position: relative;
              z-index: 1;
              margin-left: 5px;
              animation: tooltip-scale 0.3s;
            }
            .mobile-hint::before,
            .mobile-hint::after {
              --scale: 1;
              --arrow-size: 10px;
              --tooltip-color: #ffffff;

              position: absolute;
              // top: -0.25rem;
              left: 50%;
              transition: 150ms transform;
              transform-origin: top center;
            }
            .mobile-hint::before {
              --translate-y: calc(1.1rem + var(--arrow-size));
              transform: translateX(-82%) translateY(var(--translate-y, 0))
                scale(var(--scale));
              content: attr(data-tooltip);
              color: #000000;
              font-size: 12px;
              line-height: 18px;
              padding: 16px;
              border-radius: 0.3rem;
              text-align: start;
              width: max-content;
              max-width: 200px;
              background: var(--tooltip-color);
            }
            .mobile-hint::after {
              --translate-y: calc(1.15rem - var(--arrow-size));
              transform: translateX(-50%) translateY(var(--translate-y, 0))
                scale(var(--scale));
              content: "";
              border: var(--arrow-size) solid transparent;
              border-bottom-color: var(--tooltip-color);
              transform-origin: bottom center;
            }
            // end tooltip on click view
          }
        }
        .subscriptions-item:hover {
          background: #171717;
        }
        .subscription-selected {
          border: 3px solid #ffffff;
          padding: 9px 21px 16px 15px;
          background: #1e1e1e;
        }
        .code-applied {
          opacity: 0.4;
        }
      }
      .subscription-code {
        margin-top: 49px;
        .code-input {
          border: 1px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;
          border-radius: 5px;

          display: grid;
          grid-template-columns: 1fr 180px;
          margin: 16px 0 10px 0;
          input {
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            // width: 100%;
            outline: none;
            padding: 17px 16px;
            color: #ffffff;
            text-transform: uppercase;
          }
          input::placeholder {
            color: #757474;
          }
          .input-state {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            font-size: 14px;
            line-height: 14px;
            font-weight: 600;
            text-transform: uppercase;
          }
          .apply-btn {
            border-radius: 5px;
          }
          .apply-btn:hover {
            background-color: #2a2a2a;
          }
          .succes-state,
          .error-state {
            i {
              font-size: 18px;
              margin-right: 6px;
            }
          }
          .succes-state {
            color: #84bd00;
          }
          .error-state {
            color: #d1595f;
          }
        }
        .code-input__errors {
          height: 15px;

          font-size: 12px;
          line-height: 15px;
          color: #d1595f;
        }
      }
    }
    .form-section {
      padding: 67px 86px;
      #subscription-form {
        margin-top: 42px;

        .input-border {
          border: 1px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;
          border-radius: 5px;
          margin: 16px 0 34px 0;

          input {
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            width: 100%;
            outline: none;
            padding: 17px 16px;
            color: #757474;
            text-transform: uppercase;
          }
          ::placeholder {
            color: #757474;
            // line-height: 14px;
          }
        }
        .card-input {
          border: 1px solid rgba(255, 255, 255, 0.2);
          box-sizing: border-box;
          border-radius: 5px;

          padding: 14.5px 16px;
          margin: 16px 0;
        }
      }
      #card-errors {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #cf1019;
        height: 17px;
      }
      .form-buttons {
        margin-top: 20px;
        button {
          width: 100%;
          height: 52px;
        }
        .sub-loader {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }
    }
    .terms-section {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 35px 0;
      .terms-text {
        max-width: 388px;
        text-align: center;
        font-size: 12px;
        line-height: 15px;
        a {
          margin-left: 3px;
          font-weight: 700;
        }
      }
    }
    .success-subscription {
      height: 418px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .success-notification {
        display: flex;
        flex-direction: column;
        max-width: 330px;
        width: 100%;

        text-align: center;

        i {
          color: #84bd00;
          font-size: 76px;
          line-height: 66px;

          margin-bottom: 16px;
        }
        .success-text {
          display: block;
        }
        .success-text-mobile {
          display: none;
        }
        button {
          margin-top: 53px;
          height: 52px;
        }
      }
    }
  }
}
@media screen and (max-width: 499px) {
  .subscription-view {
    .logo-section {
      height: 44px;
      margin: 25px 0 32px 0;
    }
    .view-elements {
      .price-section,
      .form-section,
      .success-subscription {
        border-radius: 0;
        .section-title {
          font-size: 22px;
        }
      }
      .price-section {
        padding: 36px 16px 36px 24px;
        .subscriptions-list {
          margin-top: 10px;
        }
        .subscription-code {
          .code-input {
            grid-template-columns: 1fr 165px;
            input {
              padding: 17px 0 17px 16px;
              width: 100%;
            }
          }
        }
      }
      .form-section {
        padding: 38px 16px 38px 24px;
      }
      .terms-section {
        padding: 0 46px;
      }
      .success-subscription {
        height: 348px;
        padding: 0 22px;
        .success-notification {
          i {
            font-size: 60px;
            margin-bottom: 40px;
            line-height: 50px;
          }
          .success-text {
            display: none;
          }
          .success-text-mobile {
            display: block;
          }
          button {
            margin-top: 42px;
          }
        }
      }
    }
  }
}

@keyframes tooltip-scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
</style>